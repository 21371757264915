<template>
	<div class="LubokNotFoundView view" v-if="viewIsReady">
		<LuMobileHeader
			class="app__mobileNav"
			v-if="$root.isSmallDevice"
		></LuMobileHeader>

		<LuNotFound></LuNotFound>
	</div>
</template>

<script>
	// @ is an alias to /src
	import EventBus from '@/helper/EventBus.js'
	import restHandler from '@/mixins/restHandler/v1/restHandler.mixin.js'
	import ViewMixin from './View.mixin.js'
	import MhDevGrid from '@/components/MhDevGrid/MhDevGrid.vue'
	import MhSmartHeader from '@/components/MhSmartHeader/MhSmartHeader.vue'
	import LuMobileHeader from '@/components/LuMobileHeader.vue'
	import LuNotFound from '@/components/LuNotFound.vue'

	export default {
		name: 'LubokNotFoundView',
		components: {
			MhDevGrid,
			MhSmartHeader,
			LuMobileHeader,
			LuNotFound,
		},
		mixins: [
			restHandler,
			ViewMixin,
		],
		props: {},
		data() {
			return {}
		},
		computed: {
			currentUrl(){
				return window.location
			}
		},
		methods: {},
		created(){},
		mounted() {
			this.viewIsReady = true // this comes from View.mixin.js

			this.title = '404'
		},
	}
</script>

<style lang="less" scoped>
	@import "../less/includes/grid.include.less";

	.LubokNotFoundView {}

	@media @mediaQuery__xs {}
	@media @mediaQuery__sm {}
	@media @mediaQuery__md {}
	@media @mediaQuery__lg {}
	@media @mediaQuery__xl {}
</style>
